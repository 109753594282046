import type { ApolloContext } from '../../types';
import { safeRead, safeReadFragment } from '../../util';
import { GET_OUTBOUND_CONFERENCE_CALL_BY_USER } from '../../queries/gql/getOutboundConferenceCallByUser.gql';
import { OutboundConferenceCallFragment } from '../../fragments/gql/outboundConferenceCall.gql';

const convertOutboundCallCaller = (
  caller: PubNubOutboundConferenceCallCallerModel,
): OutboundConferenceCallFields$caller => {
  return {
    __typename: 'UserQL',
    id: caller.id,
    displayName: caller.display_name,
  };
};

const convertOutboundCallRecording = (
  callRecording: PubNubOutboundConferenceCallRecordingModel | null | undefined,
): OutboundConferenceCallFields$callRecording | null => {
  if (callRecording) {
    return {
      __typename: 'CallRecordingQL',
      id: callRecording.id,
      url: callRecording.url ?? null,
    };
  }

  return null;
};

export const convertOutboundConferenceCall = (
  outboundConferenceCall: PubNubOutboundConferenceCallModel,
): OutboundConferenceCallFields => ({
  __typename: 'OutboundConferenceCallQL',
  id: outboundConferenceCall.id,
  createdOn: outboundConferenceCall.created_on,
  caller: convertOutboundCallCaller(outboundConferenceCall.caller),
  conversationId: outboundConferenceCall.conversation_id,
  transcriptId: outboundConferenceCall.transcript_id || null,
  outboundCallSid: outboundConferenceCall.outbound_call_sid || null,
  hostMdn: outboundConferenceCall.host_mdn,
  recipientMdn: outboundConferenceCall.recipient_mdn,
  recipientDisplayName: outboundConferenceCall.recipient_display_name,
  endTime: outboundConferenceCall.end_time,
  startTime: outboundConferenceCall.start_time,
  callStatus: outboundConferenceCall.call_status,
  callDuration: outboundConferenceCall.call_duration,
  isBeingRecorded: outboundConferenceCall.is_being_recorded,
  hasCallAssist: outboundConferenceCall.has_call_assist,
  callRecording: convertOutboundCallRecording(
    outboundConferenceCall.call_recording,
  ),
});

/* eslint-disable react/destructuring-assignment */

export const PubNubOutboundConferenceCallStatusUpdated = (
  obj: any,
  args: { outbound_conference_call: PubNubOutboundConferenceCallModel },
  { client }: ApolloContext,
) => {
  const outboundConferenceCall = convertOutboundConferenceCall(
    args.outbound_conference_call,
  );

  const existingOutboundConferenceCall =
    safeReadFragment<OutboundConferenceCallFields>(client, {
      id: `OutboundConferenceCallQL:${outboundConferenceCall.id}`,
      fragment: OutboundConferenceCallFragment,
      fragmentName: 'OutboundConferenceCallFields',
    });

  const updatedOutboundConferenceCall = {
    ...existingOutboundConferenceCall,
    ...outboundConferenceCall,
  };
  client.writeFragment({
    id: `OutboundConferenceCallQL:${outboundConferenceCall.id}`,
    fragment: OutboundConferenceCallFragment,
    fragmentName: 'OutboundConferenceCallFields',
    data: updatedOutboundConferenceCall,
  });

  const byUserData = safeRead(client, {
    query: GET_OUTBOUND_CONFERENCE_CALL_BY_USER,
    variables: { userId: updatedOutboundConferenceCall.caller.id },
  });

  const existingOutboundConferenceCalls =
    byUserData?.outboundConferenceCalls ?? [];
  const updatedConferenceCalls = [
    ...existingOutboundConferenceCalls.filter(
      (call: PubNubOutboundConferenceCallModel) =>
        call.id !== updatedOutboundConferenceCall.id,
    ),
    updatedOutboundConferenceCall,
  ];
  client.writeQuery({
    query: GET_OUTBOUND_CONFERENCE_CALL_BY_USER,
    variables: { userId: updatedOutboundConferenceCall.caller.id },
    data: {
      outboundConferenceCalls: updatedConferenceCalls,
    },
  });

  return null;
};

/* eslint-enable */
