import React, { useMemo } from 'react';

import {
  createTheme,
  type Palette,
  type PaletteOptions,
} from '@mui/material/styles';

import {
  Theme,
  ThemeProvider,
  StyledEngineProvider,
  ThemeOptions,
  PaletteMode,
} from '@mui/material';
import merge from 'lodash/merge';
import { createPalette } from './palette';
import { components } from './components';
import { typography } from './typography';
import { layout } from './layout';
import { util } from './util';

declare module '@mui/styles' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module '@mui/system/createTheme/shape' {
  interface Shape {
    borderRadius: number;
    borderRadiusBig: number;
    borderRadiusHuge: number;
    borderRadiusFull: string;
    borderRadiusNone: number;
  }
}

// MUI uses true literals to enable new colors in module augmentation
// https://mui.com/material-ui/customization/palette/#typescript
interface ColorOverride {
  report: true;
  inbox: true;
  numa: true;
  customer: true;
  user: true;
  appointment: true;
  order: true;
  note: true;
  task: true;
  phonecall: true;
  payment: true;
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides extends ColorOverride {}
  interface ButtonPropsVariantOverrides {
    link: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides extends ColorOverride {}
  interface IconButtonOwnProps {
    variant?: 'filled' | 'outlined';
  }
}

declare module '@mui/material/ToggleButtonGroup' {
  interface ToggleButtonGroupPropsColorOverrides extends ColorOverride {}
}

declare module '@mui/material/CircularProgress' {
  interface CircularProgressPropsColorOverrides extends ColorOverride {}
}

declare module '@mui/material/Tooltip' {
  interface TooltipProps {
    /**
     * When enabled, the tooltip popover will take the same styling
     * as the app background (as opposed to being styled as contrast).
     */
    flipped?: boolean;
  }
}

declare module '@mui/material/ListItem' {
  interface ListItemComponentsPropsOverrides {
    to: string;
  }
}

const flipPalette = (palette: PaletteOptions): PaletteOptions => {
  const flippedPalette = { ...palette };
  for (const [key, value] of Object.entries(palette)) {
    if (value.main) {
      const paletteColor = key as keyof PaletteOptions;
      (flippedPalette[paletteColor] as Palette['primary']) = {
        main: value.contrastText,
        light: value.dark,
        dark: value.light,
        background: value.contrastText,
        contrastText: value.main,
      };
    }
  }

  return flippedPalette;
};

export const themeOptions = (
  mode: PaletteMode,
  flipped: boolean,
): ThemeOptions => {
  const palette = createPalette(mode);
  return {
    palette: flipped ? flipPalette(palette) : palette,
    components,
    typography,
    spacing: 10,
    shape: {
      borderRadius: 3,
      borderRadiusBig: 10,
      borderRadiusHuge: 20,
      borderRadiusFull: '50%',
      borderRadiusNone: 0,
    },
    layout,
    util,
  };
};

export const createCoreTheme = (
  mode: PaletteMode,
  flipped: boolean,
  options: ThemeOptions = {},
): Theme =>
  createTheme({
    ...themeOptions(mode, flipped),
    ...options,
  });

export const createMergedTheme = (
  withOptions: ThemeOptions,
  flipped: boolean,
  mode: PaletteMode = 'light',
) => createTheme(merge(themeOptions(mode, flipped), withOptions));

export const CoreThemeProvider = ({
  children,
  options,
  flipped,
  mode = 'light',
}: {
  children: React.ReactNode;
  options?: ThemeOptions;
  /* Flips the palette colors of the children components
  (useful for components on colored backgrounds) */
  flipped?: boolean;
  mode?: PaletteMode;
}) => {
  const theme = useMemo(() => {
    return createCoreTheme(mode, !!flipped, options);
  }, [mode, flipped, options]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
};
